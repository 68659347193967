var body = document.querySelector('body');
var header = document.querySelector('[page-header]');

if (header) {
    var header_height = header.offsetHeight;
    var header_nav = header.querySelector('.page-header-nav');
    var admin_bar = document.body.classList.contains('admin-bar');
    
    var setBodyPadding = function() {
        if (header.offsetHeight !== parseInt(body.style.paddingTop)) {
            header_height = header.offsetHeight;
            body.style.paddingTop = header_height + 'px';
            setNavOffset(header_height);
        }
    
        window.requestAnimationFrame(setBodyPadding);
    }
    
    
    var setNavOffset = function(offset) {
        if (window.innerWidth <= 1023) {
            header_nav.style.top = offset + 'px';
    
            if (admin_bar) {
                let admin_bar_height = document.querySelector('#wpadminbar').offsetHeight;
                header_nav.style.height = `calc(100vh - ${offset}px - ${admin_bar_height}px)`;
            } else {
                header_nav.style.height = `calc(100vh - ${offset}px)`;
            }
        } else {
            header_nav.removeAttribute('style');
        }
    }
    window.requestAnimationFrame(setBodyPadding);
}